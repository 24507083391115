import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Meta: React.FC<{}> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Meta.title', 'Play-To-Earn - Monsta.Party')}</title>
        <meta name="title" content={t('Meta.title', 'Monsta Party NFTs')} />
        <meta
          name="description"
          content={t(
            'Meta.description',
            'Monsta Party is a Play-To-Earn NFT party project that will go down in history as the biggest and most badass collection of Party Monstas on earth. Even your grandma will vibe with this party.',
          )}
        />
        <meta
          name="keywords"
          content="nft,p2e,play-to-earn,monsta,deflationary,elastic,backed,cake,pancake,swap,pancakeswap,crypto,coin,token,liquidity"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />

        {/* <!-- Twitter Card data --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@MonstaPartyNFTs" />
        <meta name="twitter:title" content="Monsta Party | An NFT Party Worth Attending" />
        <meta
          name="twitter:description"
          content={t(
            'Meta.description',
            'Monsta Party is a play-to-earn NFT party project that will go down in history as the biggest and most badass collection of Party Monstas on earth. Even your grandma will vibe with this party.',
          )}
        />
        <meta name="twitter:creator" content="@MonstaPartyNFTs" />
        <meta name="twitter:image" content="https://monsta.party/img/og-twitter.png?2" />

        {/* <!-- Open Graph data --> */}
        <meta property="og:title" content="Monsta Party" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://monsta.party/" />
        <meta property="og:image" content="https://monsta.party/img/og.png?2" />
        <meta
          property="og:description"
          content="Monsta Party is a play-to-earn NFT party project that will go down in history as the biggest and most badass collection of Party Monstas on earth. Even your grandma will vibe with this party."
        />
        <meta property="og:site_name" content="monsta.party" />
      </Helmet>
    </>
  );
};

export default Meta;
